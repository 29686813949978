<template>
  <div>
    <a-card v-if="this.searchHasRight === true" style="margin-bottom: 24px">
      <div class="table-operator" style="margin-bottom: -8px">
        <a-form layout="inline" class="feeEditLabelWidth95">
          <a-row :gutter="0" style="line-height: 3.5">
            <a-col :md="7" :sm="24">
              <a-form-item label="门店名称">
                <a-input
                  v-model="queryParam.facilityName"
                  placeholder="请输入"
                  @pressEnter="select"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="店铺名称">
                <a-input
                  v-model="queryParam.storeName"
                  placeholder="请输入"
                  @pressEnter="select"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="退租类型">
                <a-select
                  style="width: 175px"
                  v-model="queryParam.rentOutType"
                  @change="select"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in terminateList" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 3) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="
                  (advanced && { float: 'right', overflow: 'hidden' }) || {}
                "
              >
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.init())"
                  >重置</a-button
                >
              </span>
            </a-col>
          </a-row>
          <a-row :gutter="0" style="line-height: 3.5">
            <a-col :md="7" :sm="24">
              <a-form-item label="违约退租类型">
                <a-select
                  style="width: 175px"
                  v-model="queryParam.detailType"
                  @change="select"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="item in detailTypeList"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="客户状态">
                <a-select
                  style="width: 175px"
                  v-model="queryParam.tenantStatus"
                  @change="select"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in statusList" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card>
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: 1200 }"
        @change="changePageSize"
      >
        <!-- 合同编号 -->
        <span slot="contractCode" slot-scope="text, record" class="blueColor">
          <template>
            <a @click="contractDetail(record)">{{ record.contractCode }}</a
            >&nbsp;&nbsp;
          </template>
        </span>
        <!-- 退租类型 -->
        <span slot="rentOutType" slot-scope="text, record" class="blueColor">
          <template>
            <span>{{ showRentOutType(record.rentOutType) }}</span>
          </template>
        </span>
        <!-- 客户状态 -->
        <span slot="tenantStatus" slot-scope="text, record" class="blueColor">
          <template>
            <span>{{ showTenantStatus(record.tenantStatus) }}</span>
          </template>
        </span>
        <span slot="action" slot-scope="text, record" class="blueColor">
          <template>
            <a v-if="viewHasRight === true" @click="handleDetails(record)"
              >查看</a
            >&nbsp;&nbsp;
          </template>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
  
import Bus from "@/bus";
import axios from "axios";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";

export default {
  name: "terminateLease",
  data() {
    return {
      advanced: "",
      searchHasRight: true,
      viewHasRight: true,
      data: [],
      queryParam: {
        facilityName: "",
        storeName: "",
        rentOutType: undefined,
        cityId: undefined,
        kitchenBrand: undefined,
        tenantStatus: undefined
      },
      terminateList: [
        { id: 1, name: "期满退租" },
        { id: 2, name: "提前退租" },
        { id: 3, name: "违约退租" }
      ],
      detailTypeList: [
        { id: 1, name: "退租" },
        { id: 2, name: "续租" },
        { id: 3, name: "换铺" },
        { id: 4, name: "我方关店" }
      ],
      statusList: [
        { id: 1, name: "预订中" },
        { id: 2, name: "已预订" },
        { id: 3, name: "签约中" },
        { id: 4, name: "已签约" },
        { id: 5, name: "改造中" },
        { id: 6, name: "待入驻" },
        { id: 7, name: "已入驻" },
        { id: 8, name: "申请退租中" },
        { id: 9, name: "退租中" },
        { id: 10, name: "已退租" },
        { id: 11, name: "已离场" }
      ],
      pagination: defaultPagination(() => {}),
      columns: [
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "facilityName",
          width: 160,
          fixed: "left"
        },
        {
          title: "档口编号",
          dataIndex: "kitchenCode",
          key: "kitchenCode"
          // align: "center"
        },

        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName"
          // align: "center"
        },
        {
          title: "城市",
          dataIndex: "cityName",
          key: "cityName"
          // align: "center"
        },
        {
          title: "厨房品牌",
          dataIndex: "kitchenBrandName",
          key: "kitchenBrandName"
          // align: "center"
        },
        {
          title: "合同编号",
          // dataIndex: "contractCode",
          key: "contractCode",
          scopedSlots: { customRender: "contractCode" }
          // align: "center"
        },
        {
          title: "退租申请日期",
          dataIndex: "rentOutApplyDate",
          key: "rentOutApplyDate"
          // align: "center"
        },
        {
          title: "退租类型",
          // dataIndex: "rentOutType",
          key: "rentOutType",
          scopedSlots: { customRender: "rentOutType" }
          // align: "center"
        },
        {
          title: "违约退租类型",
          dataIndex: "detailTypeName",
          key: "detailTypeName"
        },
        {
          title: "客户状态",
          // dataIndex: "tenantStatus",
          key: "tenantStatus",
          scopedSlots: { customRender: "tenantStatus" }
          // align: "center"
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ]
    };
  },
  created() {
    this.init();
    this.getUserCities();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  computed: {
    ...mapGetters("cities", ["userCities"]),
    showRentOutType(value) {
      return function(value) {
        let val = "";
        this.terminateList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    },
    showTenantStatus(value) {
      return function(value) {
        let val = "";
        this.statusList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    }
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    init(current, pageSize) {
      this.data = [];
      axios({
        url:   this.$baseUrl + "rentOut/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.rows) {
          this.data = res.data.rows;
          this.data.forEach(item => {
            item.rentOutApplyDate = item.rentOutApplyDate
              ? moment(item.rentOutApplyDate).format("YYYY-MM-DD HH:mm:ss")
              : "";
          });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.data = [];
        }
      });
    },
    //合同详情
    contractDetail(val) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: val.contractId }
      });
    },
    select() {
      this.init();
    },
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    handleDetails(val) {
      this.$router.push({
        path: "/store/terminateLeaseList/terminateLeaseDetail",
        query: {
          id: val.id,
          contractId: val.contractId,
          tenantStatus: val.tenantStatus,
          facilityName: val.facilityName,
          kitchenBrand: val.kitchenBrand,
          kitchenCode: val.kitchenCode,
          storeName: val.storeName,
          cityName: val.cityName,
          contractCode: val.contractCode,
          rentOutType: val.rentOutType,
          rentOutId: val.rentOutId
        }
      });
    }
  }
};
</script>

<style scoped></style>
